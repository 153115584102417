import axios from 'axios';
// import qs from 'qs';
import { Toast  } from 'vant';
import Vue from 'vue'
import router from '@/router'
import { getRequestUrl, getHostName } from '@/api/url-util';

export const ip = getHostName();
export const url = getRequestUrl({ port: 80 });

// // 请求失败的响应信息
// const errorResponeData = {
//   msg: '请求失败',
//   code: 500,
//   data: null
// }

export const xhr = axios.create({
  baseURL: process.env.VUE_APP_API + process.env.VUE_APP_BASE_API,
  timeout: 55000, // 55秒超时
  headers: { 'Content-Type': 'application/json' },
});

xhr.interceptors.request.use(config => {
  // config.data = qs.stringify(config.data);
  return config;
}, error => Promise.reject(error));

xhr.interceptors.response.use(response => {
  const res = response.data
  if(res.code === 1001 || res.code === 1009){
    Toast.fail("您的登录状态已过期，请重新登录");
    router.push({
      path:'/login'
    })
  }else if(res.code === 1004){
    Toast.fail(res.msg);
    return Promise.reject(new Error(res.msg || 'Error'))
  }
  else if(res.code === 1403){
    router.push({
      path:'/page403'
    })
  }else if(res.code === 1404){
    router.push({
      path:'/page404'
    })
  }else {
    return res
  }
  // if(res.code === 0){
  //   return res
  // }else{
  //   Toast.fail(res.detail);
  //   return Promise.reject(new Error(res.detail || 'Error'))
  // }
  // if (response.status !== 200) return errorResponeData;
  // return response.data;
}, error => Promise.reject(error));
