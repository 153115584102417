import { xhr } from './request';
import { uuid } from '@/utils/utils';
// import { loginOut } from './secure'
// export { loginOut };


const post = (url, p) => xhr.post('/' + url, JSON.stringify(p))



// 招商视频
export const getSiteVideo = p => post('home/getSiteVideo', p)

// 生活列表
export const lifeList = p => post('life/lifeList', p)

// 重点招商企业产业链
export const getCompanyFirstCategory = p => post('home/getCompanyFirstCategory', p)

// 重点招商企业类型选择
export const getSubCategory = p => post('home/getSubCategory', p)

// 重点招商企业列表
export const companyPageList = p => post('company/companyPageList', p)
// 重点招商企业详情
export const companyDetail = p => post('company/companyDetail', p)
// 项目分页列表
export const projectPageList = p => post('project/projectPageList', p)
// 项目详情
export const projectDetail = p => post('project/projectDetail', p)
// 项目分类
export const getProjectCategory = p => post('project/getProjectCategory', p)
// 武夷生活详情
export const lifeDetail = p => post('life/lifeDetail', p)
// 意见建议
export const suggestAdd = p => post('suggest/suggestAdd', p)
// 地图密码验证
export const verifyPass = p => post('home/verifyPass', p)
// 地图
export const getPrivateMap = p => post('home/getPrivateMap', p)